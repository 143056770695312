<template>
  <div>
    <el-row>
      <el-col :span="12">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item to="/income">我的收益</el-breadcrumb-item>
          <el-breadcrumb-item>确认收益</el-breadcrumb-item>
        </el-breadcrumb>
      </el-col>
      <el-col :span="12" style="text-align: right">
        <el-link type="primary" href="#/withdrawal">收益明细</el-link>
      </el-col>
    </el-row>

    <el-divider></el-divider>

    <div class="title">
      您有<span>{{ incomeListUnchecked.money }}</span>元收益待提现
    </div>

    <el-table
        ref="multipleTable"
        :data="incomeListUnchecked.list"
        style="width: 100%;margin-top: 25px"
        @selection-change="handleSelectionChange">
      <el-table-column
          type="selection">
      </el-table-column>
      <el-table-column
          prop="title"
          label="账单名称">
      </el-table-column>
      <el-table-column
          prop="type"
          label="账单分类"
          :formatter="getOrderTypeById">
      </el-table-column>
      <el-table-column
          prop="paymentCompany"
          label="付款方">
      </el-table-column>
      <el-table-column
          v-slot="{row}"
          label="金额(元)">
        {{ row.money | toDecimal2}} <el-link style="color: #FF004D">详情</el-link>
      </el-table-column>
    </el-table>

    <div style="display: flex;align-items: center;justify-content: space-between;background-color: #F9F9F9;">
      <div style="padding: 0 14px;display: flex">
        <el-checkbox v-model="checkAll" @change="allSelection" :indeterminate="isIndeterminate">
          <div style="margin-left: 20px;color: #666666">全选</div>
        </el-checkbox>
      </div>
      <div style="display: flex;align-items: center;font-size: 14px">
        <div style="margin-right: 14px">
          <span style="color: #999999;font-weight: 500">合计</span>
          <span style="color: #FF004D">￥</span>
          <span style="color: #FF004D;font-size: 24px;">{{ totalMoney }}</span>
        </div>
        <div>
          <el-button
              type="primary"
              style="padding: 14px 20px"
              :disabled="multipleSelection.length === 0"
              @click="centerDialogVisible = true"
          >确认账单</el-button>
        </div>
      </div>
    </div>

    <el-dialog
        title="提示"
        :visible.sync="centerDialogVisible"
        width="30%"
        center>
      <div style="text-align: center">账单一经确认不可撤回，请确认账单是否无误？</div>
      <span slot="footer" class="dialog-footer">
    <el-button type="info" @click="centerDialogVisible = false">取 消</el-button>
    <el-button type="primary" @click="submit()">确 定</el-button>
  </span>
    </el-dialog>

  </div>
</template>

<script>
import wallet from "@/api/wallet"

export default {
  name: "Unchecked",
  components: {
  },
  data() {
    return {
      incomeListUnchecked: {},
      multipleSelection: [],

      checkAll: false,
      isIndeterminate: false,

      centerDialogVisible: false
    }
  },
  mounted() {
    this.getIncomeListUnchecked()
  },
  methods: {
    allSelection(value) {
      this.isIndeterminate = false;
      if (value){
        this.$refs.multipleTable.toggleAllSelection()
      } else {
        this.$refs.multipleTable.clearSelection()
      }
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
      let checkedCount = val.length
      this.checkAll = checkedCount === this.incomeListUnchecked.list.length;
      this.isIndeterminate = checkedCount > 0 && checkedCount < this.incomeListUnchecked.list.length;
    },
    getIncomeListUnchecked(){
      wallet.incomeListUnchecked().then(r => {
        this.incomeListUnchecked = r.data
      })
    },
    getOrderTypeById(_, __, id){
      // 账单类型（后台字典类型10016）：1001601账单分成 1001602版税分成 1001603词曲收入 1001604其他。以后新增和编辑维护
      switch (id) {
        case '1001601': return '账单分成'
        case '1001602': return '版税分成'
        case '1001603': return '词曲收入'
        case '1001604': return '其他'
        default: return '其他'
      }
    },
    submit(){
      let ids = []
      this.multipleSelection.forEach(e => {
        ids.push(e.id)
      })
      wallet.incomeDetailCheck(ids.join(',')).then(r => {
        if(r.code === 0){
          // todo
        }
      })
    }
  },
  computed: {
    totalMoney(){
      let t = 0
      this.multipleSelection.forEach(e => {
        t += e.money
      })
      return t;
    }
  }
}
</script>

<style scoped lang="scss">

.title {
  font-size: 24px;
  color: #333;
  font-weight: 500;
  margin-top: 16px;
  span {
    color: #ff477e;
  }
}
</style>
